<template>
    <div :class="`application-selector application-selector--${light ? 'light' : 'dark'}`">
        <div class="application-selector__top">
            <span
                >{{ title }}
                <v-btn class="ml-2" x-small icon v-if="isReviewPage"
                    ><v-icon small color="white"> mdi-pencil </v-icon></v-btn
                ></span
            >
        </div>
        <div class="application-selector__content">
            <h4>{{ isReviewPage ? 'Application type' : 'Choose the application type' }}</h4>
            <span v-if="!isReviewPage">Select the type of application for this package</span>

            <div v-if="isReviewPage" class="my-2"></div>

            <div class="form__group">
                <v-select
                    placeholder="Select one option"
                    v-on:change="onApplicationSelected($event)"
                    :items="applicationOptions"
                    filled
                ></v-select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ApplicationSelector',
    data() {
        return {
            applicationOptions: ['Lease', 'Purchase', 'Guest', 'All'],
            title: 'Tenant Standard Package',
        };
    },
    props: {
        light: { type: Boolean, value: false },
        isReviewPage: { type: Boolean, value: false },
    },
    methods: {
        onApplicationSelected(value) {
            this.$emit('onApplicationSelected', value);
        },
    },
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';

.application-selector {
    &__top {
        background: $secondary-color;
        padding: 10px 32px;
        border-top-right-radius: 20px;
        display: inline-flex;
        align-items: center;

        span {
            color: $white-color;
            font-size: 15px;
            font-family: $alternate-font;
            font-weight: 900;
        }
    }

    &__content {
        background: $primary-color;
        padding: 25px 45px;

        h4,
        span {
            color: $white-color;
        }

        h4 {
            font-family: $alternate-font;
            font-weight: 900;
            font-size: 20px;
            line-height: 25px;
        }

        span {
            font-size: 12px;
            line-height: 22px;
            margin-bottom: 14px;
            display: block;
        }
    }

    &--light {
        .application-selector {
            &__content {
                background: $white-color;
                h4,
                span {
                    color: $text-color;
                }
            }
        }
    }
}
</style>
