<template>
    <div class="total-result">
        <div class="total-result__member">
            {{ member }}
            <span>Rate</span>
        </div>

        <div class="total-result__total">
            <span>Total (USD): ${{ total }}.<small>00</small></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TotalResultComponent',
    props: {
        total: Number,
        member: String,
    },
}
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';

.total-result {
    width: 100%;
    background: $primary-color;
    padding: 12px 25px;
    display: flex;
    justify-content: space-between;
    font-family: $alternate-font;

    &__member {
        font-style: italic;
        font-size: 22px;
        color: $gold-color;
        font-weight: bold;

        span {
            color: $white-color;
        }
    }

    &__total {
        color: $white-color;
        font-weight: 700;
        font-size: 20px;
    }
}
</style>
