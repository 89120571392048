<template>
    <div class="heading">
        <h1 class="headline headline--h2 headline--alternate" v-html="title"></h1>
        <small v-html="subtitle"></small>
    </div>
</template>

<script>
export default {
    name: 'HeadingComponent',
    props: {
        title: String,
        subtitle: String,
    },
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';
.heading {
    .headline {
        line-height: 40px;
    }

    small {
        font-family: $alternate-font;
        color: $gray-500;
        font-size: 12px;
    }
}
</style>